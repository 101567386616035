<template>
  <div>
    <a-spin :spinning="loading" tip="Processing audio...">
      <h2>Upload Audio for Analysis</h2>

      <!-- Custom Drag-and-Drop Area -->
      <div
        @dragover.prevent
        @drop.prevent="onFileDrop"
        class="upload-area"
      >
        Drag & drop your FLAC file here
      </div>

      <p v-if="audioFileName">File: {{ audioFileName }}</p>

      <!-- Prompt Text Area -->
      <a-textarea
        v-model="promptText"
        placeholder="Enter analysis prompt"
        rows="4"
        style="margin-top: 10px; width: 100%;"
      />

      <!-- Submit Button (disabled until prompt and audio are provided) -->
      <a-button
        type="primary"
        :disabled="!audioFile || !promptText"
        @click="uploadAudio"
        style="margin-top: 10px;"
      >
        Submit for Analysis
      </a-button>
    </a-spin>
    <a-divider></a-divider>
    <!-- Display analysis result -->
    <div v-if="analysisResult" style="margin-top: 20px;">
        <h3>Analysis Result</h3>
        <div v-html="analysisResultHtml"></div>
      </div>

      <!-- Display transcript section (toggle visibility) -->
      <div v-if="transcriptText" style="margin-top: 20px;">
        <a-button @click="toggleTranscript">
          {{ showTranscript ? 'Hide Transcript' : 'Show Transcript' }}
        </a-button>
        <div v-if="showTranscript" style="margin-top: 10px; white-space: pre-wrap;">
          <h3>Transcript</h3>
          <p>{{ transcriptText }}</p>
        </div>
      </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { firebaseAuth } from '@/services/firebase' // Import Firebase Auth
import axios from 'axios' // Import Axios
import marked from 'marked'

export default {
  data() {
    return {
      audioFile: null, // Holds the uploaded audio file
      audioFileName: '', // Holds the name of the uploaded file
      promptText: '', // Holds the prompt text
      loading: false, // Spinner state
      analysisResult: '', // Stores the analysis result from the backend
      transcriptText: '', // Stores the transcript text from the backend
      showTranscript: false, // Controls whether the transcript is visible
    }
  },
  computed: {
    // Convert the analysis result to HTML using marked for Markdown rendering
    analysisResultHtml() {
      return marked(this.analysisResult)
    },
  },
  methods: {
    toggleTranscript() {
      this.showTranscript = !this.showTranscript
    },
    // Handle drag-and-drop file
    onFileDrop(event) {
      const file = event.dataTransfer.files[0]

      // Validate the file type
      const isFlac = file && file.type === 'audio/flac'
      if (!isFlac) {
        message.error('Only FLAC files are accepted!')
        return
      }

      // Set the file and file name
      this.audioFile = file
      this.audioFileName = file.name
      message.success(`File ${file.name} uploaded successfully!`)
    },
    // Uploads the audio file and performs the analysis
    async uploadAudio() {
      if (!this.audioFile || !this.promptText) {
        message.error('Please upload a FLAC audio file and provide a prompt.')
        return
      }

      this.loading = true // Show spinner

      const reader = new FileReader()
      reader.readAsDataURL(this.audioFile) // Convert audio to base64

      reader.onload = async () => {
        const base64Audio = reader.result.split(',')[1] // Extract base64 data

        try {
          // Get the Firebase Auth token
          const currentUser = firebaseAuth.currentUser
          if (!currentUser) {
            message.error('User is not authenticated.')
            return
          }

          const idToken = await currentUser.getIdToken()

          // Call the Google Cloud Function with Axios and Firebase Auth token
          const response = await axios.post(
            'https://europe-west1-skyportal2.cloudfunctions.net/analyze_audio_gcf',
            {
              audio: base64Audio, // Pass base64 encoded audio
              prompt: this.promptText, // Pass prompt
            },
            {
              headers: {
                Authorization: `Bearer ${idToken}`, // Include Firebase Auth token in the headers
                'Content-Type': 'application/json',
              },
            },
          )

          console.log('Analysis Result:', response.data)
          message.success('Audio analysis completed!')
          // Set the analysis and transcript result in the component state
          this.analysisResult = response.data.analysis
          this.transcriptText = response.data.transcript
        } catch (error) {
          console.error('Error analyzing audio:', error)
          message.error('Error analyzing audio!')
        } finally {
          this.loading = false // Hide spinner
        }
      }
    },
  },
}
</script>

<style>
.upload-area {
  width: 100%;
  height: 150px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  cursor: pointer;
}
.upload-area:hover {
  border-color: #1890ff;
  color: #1890ff;
}
</style>
